import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import {
  EmissionStatus,
  GPSStatus,
  GSMStatus,
  DateStatus,
  BatteryStatus,
  VersionStatus,
  PositionStatus
} from "../../../../common/trackers/trackerStatus";

const TrackerItem = (props) => {
  const {
    imei,
    have_emitted,
    have_gsm,
    have_gps,
    last_emission,
    last_gps,
    last_position,
    battery,
    printer,
    onPrint,
    isLink,
    version,
    id,
    onUpdate
  } = props;
  const battery_valid = battery >= 380;
  const navigate = useNavigate();

  function handleLineClick(e) {
    if (isLink) {
      navigate("/trackers/id/" + id, { replace: true });
    }
  }

  var positionRef ={
    'latitude':44.56683,
    'longitude':6.07355
  }

  var print_enabled =
    printer === "Open" && have_gps && battery_valid && have_gsm;
  return (
    <tr onClick={handleLineClick}>
      <th scope="row">{imei}</th>
      <EmissionStatus emissionStatus={have_emitted} />
      <GSMStatus gsmStatus={have_gsm} />
      <GPSStatus gpsStatus={have_gps} />
      <DateStatus date={last_emission} />
      <DateStatus date={last_gps} />
      <PositionStatus position={last_position} positionRef={positionRef}/>
      <BatteryStatus batteryLevel={battery} batteryStatus={battery_valid} />
      <VersionStatus version={version} />
      <td>
        <Button
          color="primary"
          size="sm"
          outline="true"
          onClick={() => onUpdate(imei)}
        >
          Update
        </Button>
      </td>
      <td>
        <Button
          color="primary"
          size="sm"
          outline="true"
          disabled={!print_enabled}
          onClick={() => onPrint(imei)}
        >
          Print
        </Button>
      </td>
    </tr>
  );
};

export { TrackerItem };

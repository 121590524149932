import React, { useEffect, useRef, useState } from "react";
import Moment from "react-moment";
const DateStatus = (props) => {
  const { date } = props;
  useEffect(() => {
    console.log(date);
  }, [
    date
  ]);
  return <td>{date ? <Moment fromNow>{date}</Moment> : "Never"}</td>;
};

export { DateStatus };

import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";

const TransmissionItem = (props) => {
    const { raw } = props;
    const [rawData, setRawData] = useState({
        version : undefined,
        date : undefined,
        event : undefined,
        battery : undefined,
        satellite : undefined,
        position : undefined,
        technology : undefined,
        cell : undefined,
        rssi : undefined,
    });
    useEffect(() => {
       var elems = raw.split(',')
       setRawData({
        version : elems[1],
        date : elems[2] + " " + elems[3],
        satellite:elems[13],
        event : elems[6],
        battery : (elems[7]/100).toFixed(2),
        position : elems[8]/1000000000 + " " + elems[9]/1000000000,
        technology : elems[18],
        cell : elems[20] + " " + elems[21],
        rssi : elems[19],
       })
      }, [raw]);


  return (
    <tr>
        <td>
            {rawData.version}
        </td>
        <td>
            {rawData.date}
        </td>
        <td>
            {rawData.event}
        </td>
        <td>
            {rawData.battery}
        </td>
        <td>
            {rawData.satellite}
        </td>
        <td>
            {rawData.position}
        </td>
        <td>
            {rawData.technology}
        </td>
        <td>
            {rawData.cell}
        </td>
        <td>
            {rawData.rssi}
        </td>
    </tr>
  );
};

export { TransmissionItem };

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Map } from "./map";
import { BatteryCharts } from "./charts";
import { Col, Row, Table } from "reactstrap";
import { TransmissionsTable } from "../../../../common/transmissions/transmissionTable";
import { TransmissionItem } from "./transmissions";
import { useNavigate } from "react-router-dom";

const TrackerSummary = () => {
  const { trackerimei } = useParams();
  const [tracker, setTracker] = useState({});
  const [transmissions, setTransmissions] = useState([]);
  const [shownTransmissions, setShownTransmissions] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {

      axios
        .get("/api/trackers/search_by_imei/?imei=" + trackerimei)
        .then((res) => {
          console.log(res.data.results)
          setTracker(res.data.results[0]);
          setTransmissions(res.data.results[0].transmissions);
        });
    }

    fetchData();
  }, [trackerimei]);

  useEffect(() => {

      if(tracker)
        console.log(tracker)
   
  }, [tracker]);


  useEffect(() => {
    if(transmissions != undefined){
      var list = [];
      transmissions.forEach((t) =>{
        if(t.raw != "")
          console.log(t.raw);
          list.push(
            <TransmissionItem
              raw={t.raw}
            />
          );
        });
        setShownTransmissions(list);
    }
 
}, [transmissions]);

 function handleHeaderClick(e) {
      navigate("/test", { replace: true });
    
  }


  return (
    <main className="container" onClick={handleHeaderClick}>
      <Row>
          <Table>
            <thead>
              <tr>
                <th>
                  Version
                </th>
                <th>
                  Date
                </th>
                <th>
                  Event
                </th>
                <th>
                  Battery
                </th>
                <th>
                  Satellite
                </th>
                <th>
                  Position
                </th>
                <th>
                  Technology
                </th>
                <th>
                  Cell
                </th>
                <th>
                  RSSI
                </th>
              </tr>
            </thead>
            <tbody>{shownTransmissions}</tbody>
          </Table>
      </Row>
    </main>
  );
};
export default TrackerSummary;

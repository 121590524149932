import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.css";
import "./index.scss";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import {
  Home,
  Trackers,
  Tracker,
  TrackerMapOnly,
  Production,
  UpdateTrackers,
  UpdateIcc,
} from "./pages";

import { TableTrackers } from "./common/trackers/TableTrackers";
import { TableRenderedTracker } from "./common/trackers/TableRenderedTracker";
import { CookiesProvider } from "react-cookie";
import axios from "axios";
import { TransmissionsTable } from "./common/transmissions/transmissionTable";
const headers = [
  {
    label: "IMEI",
    isFilteringInput: true,
  },
  {
    label: "Have emitted",
    isFilteringInput: false,
  },
  {
    label: "GSM",
    isFilteringInput: false,
  },
  {
    label: "GPS",
    isFilteringInput: false,
  },
  {
    label: "Last Emission",
    isFilteringInput: false,
  },
  {
    label: "Last GPOS",
    isFilteringInput: false,
  },
  {
    label: "Position",
    isFilteringInput: false,
  },
  {
    label: "Battery",
    isFilteringInput: false,
  },
  {
    label: "Version",
    isFilteringInput: false,
  },
  {
    label: "Update",
    isFilteringInput: false,
  },
  {
    label: "Label",
    isFilteringInput: false,
  },
];
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "test",
    element: (
      <TableTrackers headers={headers} renderTracker={TableRenderedTracker} />
    ),
  },
  {
    path: "trackers",
    element: (
      <Trackers
        linkToDetails={true}
        legacyTransmission={false}
        printerActivate={false}
      />
    ),
    loader: async ({ params }) => {
      return axios.get("/api/trackers");
    },
  },
  {
    path: "trackers/production",
    element: (
      <Trackers
        linkToDetails={false}
        legacyTransmission={false}
        printerActivate={false}
      />
    ),
    loader: async ({ params }) => {
      return axios.get("/api/trackers/search_by_legacy_account?account=539");
    },
  },
  {
    path: "production/list_valid_gps",
    element: (
      <Trackers
        linkToDetails={false}
        legacyTransmission={true}
        printerActivate={true}
      />
    ),
    loader: async ({ params }) => {
      return axios.get("/api/trackers/search_by_legacy_account?account=539");
    },
  },
  {
    path: "trackers/account/:accountid",
    element: (
      <Trackers
        linkToDetails={true}
        legacyTransmission={false}
        printerActivate={false}
      />
    ),
    loader: async ({ params }) => {
      return axios.get(
        "/api/trackers/search_by_legacy_account?account=" + params.accountid
      );
    },
  },
  {
    path: "trackers/id/:trackerid",
    element: <Tracker />,
  },
  {
    path: "trackers/imei/:trackerimei",
    element: <Tracker />,
  },
  {
    path: "maps/:trackerimei",
    element: <TrackerMapOnly />,
  },
  {
    path: "production",
    element: <Production />,
  },
  {
    path: "update/trackers",
    element: <UpdateTrackers />,
  },

  {
    path: "update/icc",
    element: <UpdateIcc />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <CookiesProvider>
      <RouterProvider router={router} />
    </CookiesProvider>
  </React.StrictMode>
);

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Scatter } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
    },
  },
  scales: {
    y: {
      min: 3.0,
      max: 4.2,
      ticks: {
        stepSize: 0.2,
      },
    },
    y1: {
      
      min: -110,
      max: -30,
      display: true,
      position: 'right',

      grid: {
        drawOnChartArea: false, 
      },
    },
    y2: {
      
      min: 0,
      max: 20,
      display: true,
      position: 'right',

      grid: {
        drawOnChartArea: false, 
      },
    },
    x: {
      ticks: {
        stepSize: 120 * 60 * 1000,
        callback: function (value, index, ticks) {
          var date = new Date(value);
          var hours = date.getHours();
          if (index == 0 || hours == 0 || hours == 1)
            return date.toLocaleDateString();
          else return hours + "h";
        },
      },
    },
  },
};

const BatteryCharts = (props) => {
  const { data, imei } = props;
  options.plugins.title.text = "Tracker " + imei;
  return <Scatter options={options} data={data} />;
};

export { BatteryCharts };

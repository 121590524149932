import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const LazyLoadTrackers = (props) => {
  const { renderChild,
          bottomReached,
          imeiFilter
          } = props;
  const [trackers, setTrackers] = useState([]);
  const [next, setNext] = useState("/api/trackers/?page=1");
  const [renderItems, setRenderItems] = useState([]);


  const navigate = useNavigate();

  // Load page of trackers (100 per page) if the page is valid
  function loadNextPage() {
    if (next) {
      axios.get(next).then((res) => {
        next == 1
          ? setTrackers(res.data.results)
          : setTrackers([...trackers, ...res.data.results]);
        setNext(res.data.next);
      });
    }
  }
  
  function updateSelection(imei){
    console.log("update");
    axios
    .get("/api/trackers/search_by_imei/?imei=" + imei)
    .then((res) => {
      setNext("/api/trackers/?page=1");
      setTrackers(res.data.results);
    });

    axios.post("https://api.1nce.com/management-api/oauth/token/",{
      "grant_type": "client_credentials"
    },{
      headers:{
        "accept": "application/json",
        "authorization": "Basic ODEwMDgyMTlfMjAyNDpIb290MjAzMCM=",
        "content-type": "application/json"
      }
    }).then((res)=>{
      if(res.status_code == 200){
        var token = res.access_token;

        trackers.forEach((t)=>{
          if(t.imei == imei){
            var icc = t.icc_id.toString();
        
            axios.get("https://api.1nce.com/management-api/v1/sims/"+icc.substring(0, icc.length - 1)+"/quota/data/",{
              headers:{
                "accept": "application/json",
                "content-type": "application/json",
                'authorization': 'Bearer ' + token
              }
            }).then((res)=>{
              if(res.status_code == 200){
                console.log(res.data)
              }
            })

            return;
          }
        })

        
      }
    })
  }

  function handleLineClick(imei) {
      navigate("/trackers/imei/" + imei, { replace: true });
  }

  // Reset the page to the first and load it
  function loadFirstPage() {
    setTrackers([]);
    loadNextPage();
  }
  // Initialisation
  useEffect(() => {
    loadNextPage();
  }, []);

  // Load next elements if bottom reached
  useEffect(() => {
    if (bottomReached) {
      console.log("bottom reached");
      loadNextPage();
    }
  }, [bottomReached]);

  // Render elements when trackers change
  useEffect(() => {
    var toRender = [];
    trackers.forEach((tracker) => {
      toRender.push(renderChild(tracker,updateSelection,handleLineClick));
    });
    setRenderItems(toRender);
    console.log(trackers);
  }, [trackers, setRenderItems]);

  // Load only match
  useEffect(() => {
    if (imeiFilter.length > 2) {
      axios
        .get("/api/trackers/search_by_imei/?imei=" + imeiFilter)
        .then((res) => {
          setNext("/api/trackers/?page=1");
          setTrackers(res.data.results);
        });
    } else if (imeiFilter.length == 0) {
      loadFirstPage();
    }
  }, [imeiFilter]);

  return <>{renderItems}</>;
};

export { LazyLoadTrackers };

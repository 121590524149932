import React  from "react";
import { Button } from "reactstrap";
import {
  EmissionStatus,
  GPSStatus,
  GSMStatus,
  DateStatus,
  BatteryStatus,
  VersionStatus,
  PositionStatus
} from "./trackerStatus";

const TableRenderedTracker = (tracker,updateSelection,goDetail) => {

  var positionRef ={
    'latitude':44.56683,
    'longitude':6.07355
  }

  var have_emitted = false;
  var have_battery = false;
  var have_gsm = false;
  var have_gps = 0;
  var battery = 0;
  var last_emission;
  var last_position;
  var last_gps;
  tracker.transmissions.forEach((transmission) => {
    if (!have_emitted) {
      have_emitted = true;
      have_gsm = true;
      last_emission = transmission.date;
    }
    if(!have_gps){
      last_position = { 'latitude' :transmission.lat, 'longitude': transmission.long };
      last_gps= transmission.date;
    }
    if (transmission.battery > 0 && !have_battery) {
      battery = transmission.battery;
      have_battery = true;
    }

    if (transmission.satellites > 0) have_gps++;
  });


  const battery_valid = battery >= 380;


  function lineClick(e){
    goDetail(tracker.imei);
  }


  return (
    <tr >
      <th scope="row" onClick={lineClick} >{tracker.imei}</th>
      <EmissionStatus emissionStatus={have_emitted} />
      <GSMStatus gsmStatus={have_gsm} />
      <GPSStatus gpsStatus={have_gps} />
      <DateStatus date={last_emission} />
      <DateStatus date={last_gps} />
      <PositionStatus position={last_position} positionRef={positionRef}/>
      <BatteryStatus batteryLevel={battery} batteryStatus={battery_valid} />
      <VersionStatus version={tracker.firmware_version} />
      <td>
        <Button
          color="primary"
          size="sm"
          outline="true"
          onClick={updateSelection}
        >
          Update
        </Button>
      </td>
      <td>
        <Button
          color="primary"
          size="sm"
          outline="true"
        >
          Print
        </Button>
      </td>
    </tr>
  );
};

export { TableRenderedTracker };

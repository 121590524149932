import React, { useEffect, useRef, useState } from "react";
import { Table, Input } from "reactstrap";
import { useLoaderData } from "react-router-dom";
import { TrackerItem } from "./TrackerItem";
import { LabelPrinter } from "../../../../common/printer";
import { SerialNumberModal } from "./SerialNumberModal";
import axios from "axios";
const TrackersList = (props) => {
  const { linkToDetails, legacyTransmission, printerActivate } = props;
  const loadedData = useLoaderData();
  const [modal, setModal] = useState(false);
  const [printImei, setPrintImei] = useState("");
  const [imeiFilter, setImeiFilter] = useState("");
  const inputImei = useRef("");

  const [emitted, setEmitted] = useState(0);
  const [gps, setGPS] = useState(0);
  const [gsm, setGSM] = useState(0);
  const [total, setTotal] = useState(0);

  const [trackers, setTrackers] = useState(loadedData.data.results);
  const [shownTrackers, setShownTrackers] = useState([]);
  const [printerStatus, setPrinterStatus] = useState("Closed");
  const [printData, setPrintData] = useState("");
  const [transmissionLoaded, setTransmissionLoaded] = useState(false);

  var current_index = 0;

  function updateTrackers() {
    var list = [];
    var emitted_count = 0;
    var gps_count = 0;
    var gsm_count = 0;
    trackers.forEach((tracker, index) => {
      if (tracker.imei.includes(imeiFilter)) {
        var have_emitted = false;
        var have_battery = false;
        var have_gsm = false;
        var have_gps = 0;
        var battery = 0;
        var last_emission;
        var last_position;
        var last_gps;
        tracker.transmissions.forEach((transmission) => {
          if (!have_emitted) {
            have_emitted = true;
            have_gsm = true;
            last_emission = transmission.date;
          }
          if(!have_gps){
            last_position = { 'latitude' :transmission.lat, 'longitude': transmission.long };
            last_gps= transmission.date;
          }
          if (transmission.battery > 0 && !have_battery) {
            battery = transmission.battery;
            have_battery = true;
          }

          if (transmission.satellites > 0) have_gps++;
        });

        if (have_emitted) emitted_count = emitted_count + 1;
        if (have_gps > 0) gps_count = gps_count + 1;
        if (have_gsm) gsm_count = gsm_count + 1;

        list.push(
          <TrackerItem
            key={tracker.imei}
            imei={tracker.imei}
            have_emitted={have_emitted}
            have_gsm={have_gsm}
            have_gps={have_gps}
            last_position={last_position}
            battery={battery}
            last_emission={last_emission}
            last_gps={last_gps}
            printer={printerStatus}
            onPrint={handlePrintData}
            onUpdate={handleUpdate}
            version={tracker.firmware_version}
            id={tracker.id}
            isLink={linkToDetails}
          />
        );
      }

      setGPS(gps_count);
      setGSM(gsm_count);
      setEmitted(emitted_count);
      setTotal(list.length);
    });
    setShownTrackers(list);
  }

  useEffect(() => {
    console.log(trackers);
    updateTrackers();
  }, [
    trackers,
    imeiFilter,
    linkToDetails,
    printerStatus,
    legacyTransmission,
    transmissionLoaded,
  ]);

  function update_one_transmission(tracker_index) {
    var tracker = trackers[tracker_index];
    console.log("Searching ",tracker.imei, tracker.legacy_asset);
    if (tracker.legacy_asset > 0) {
      axios.get("/api/trackers/search_by_imei?imei=" + tracker.imei).then((res) => {
        console.log(res);
        if (res.status == 200 && res.data.results.length>0) {
          var t = res.data.results[0];
         
          
          trackers[tracker_index] = t;
          setTransmissionLoaded(true);
          setTrackers([...trackers]);
        }
      });
    }
  }


  function handlePrinterStatus(status) {
    setPrinterStatus(status);
  }

  function handleUpdate(imei) {
    console.log("Update",imei);
    trackers.forEach((tracker, index) => {
      if(tracker.imei == imei)
        update_one_transmission(index);
    })
  }

  function handlePrintData(data) {
    setModal(true);
    setPrintImei(data);
  }

  function handleValidate(sn) {
    setPrintData(
      "^Q25,4\r\n^W53\r\n^H19\r\n^P1\r\n^S2\r\n^AT\r\n^C1\r\n^R3\r\n~Q+3\r\n^O0\r\n^D0\r\n^E12\r\n~R255\r\n^L\r\nDy2-me-dd\r\nTh:m:s\r\nY16,8,Hoot_label\r\nW48,353,5,2,H0,8,10,15,0\r\n" +
        printImei +
        "\r\nAD,404,391,2,2,0,0E," +
        printImei +
        "\r\nBA3,505,41,2,6,80,0,3," +
        sn +
        "\r\nE\r\n\r\n"
    );
    setModal(false);
  }

  function imeiFilterCheck(imei){
    console.log("Check for" + imei);
    var searchImei = imei;
    if(imei.length > 15){
      var n = imei.search(/\d{15}/);
      console.log("Result " + n);
      if(n != -1){
        searchImei = imei.substring(n, n+15);
      }
    }
    console.log(searchImei);
    setImeiFilter(searchImei);
  }

  function toggleModal() {
    setModal(!modal);
  }
  return (
    <main className="container">
      <Table>
        <thead>
          <tr>
            <th>
              <Input
                placeholder="Search for Imei"
                bsSize="sm"
                innerRef={inputImei}
                onChange={() => imeiFilterCheck(inputImei.current.value)}
              ></Input>
            </th>
            <th>
              Have emitted {emitted}/{total}
            </th>
            <th>
              GSM {gsm}/{total}
            </th>
            <th>
              GPS {gps}/{total}
            </th>
            <th>Last emission</th>
            <th>Last GPS</th>
            <th>Position</th>
            <th>Battery</th>
            <th>Version</th>
            <th>Update</th>
            <th>Label</th>
          </tr>
        </thead>
        <tbody>{shownTrackers}</tbody>
      </Table>
      <LabelPrinter
        onStatusChange={handlePrinterStatus}
        printData={printData}
        printerActivate={printerActivate}
      ></LabelPrinter>
      <SerialNumberModal
        onValidate={handleValidate}
        modal={modal}
        toggle={toggleModal}
      ></SerialNumberModal>
    </main>
  );
};

export default TrackersList;

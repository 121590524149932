import React from "react";
import { Badge } from "reactstrap";
const VersionStatus = (props) => {
  const { version } = props;
  return (
    <td>
      <Badge color={(version == "1.2.3+0")? "success" : "danger"}>
        {version}
      </Badge>
    </td>
  );
};

export { VersionStatus };
